import React from 'react';
export const Banner01DataSource = {
  wrapper: { className: 'banner0' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title m3sim6n94af-editor_css',
    children:
      'https://powersuit.oss-cn-chengdu.aliyuncs.com/common/LOGO-%E5%9B%BE%E7%99%BD-256x256.png',
  },
  content: {
    className: 'banner0-content m3wdgzyu90n-editor_css',
    children: (
      <span>
        <span>
          <span>
            <p>- - 南星泽兰 - -</p>
          </span>
        </span>
      </span>
    ),
  },
  button: {
    className: 'banner0-button m3wn1g91tf-editor_css',
    children: (
      <span>
        <span>
          <br />
        </span>
      </span>
    ),
    type: 'primary',
    href: '',
  },
};
export const Nav20DataSource = {
  isScrollLink: true,
  wrapper: { className: 'header2 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header2-logo',
    children:
      'https://powersuit.oss-cn-chengdu.aliyuncs.com/common/LOGO-%E6%96%87%E7%99%BD.png',
  },
  LinkMenu: {
    className: 'header2-menu',
    children: [
      {
        name: 'linkNav',
        to: 'Feature3_0',
        children: '企业简介',
        className: 'menu-item',
      },
      {
        name: 'linkNav~m3wmjxe71t6',
        to: 'Pricing0_0',
        children: '商城列表',
        className: 'menu-item',
      },
      {
        name: 'linkNav~m3wmoqmnap',
        to: 'Content5_1',
        children: '产品列表',
        className: 'menu-item',
      },
      {
        name: 'linkNav~m3wmorkqc',
        to: 'Content5_0',
        children: '战略合作',
        className: 'menu-item',
      },
      {
        name: 'linkNav~m3wmos9bd8i',
        to: 'Footer1_0',
        children: '联系我们',
        className: 'menu-item',
      },
    ],
  },
  mobileMenu: { className: 'header2-mobile-menu' },
};
export const Feature30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <p>企业简介</p>
              </span>
            </span>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content m3sitx9vb1q-editor_css',
        children: (
          <span>
            <span>
              <p>陕西南星泽兰商贸有限公司</p>
            </span>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>云计算</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children:
              '云资源集中编排、弹性伸缩、持续发布和部署，高可用及容灾。',
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '云安全' },
          content: {
            className: 'content3-content',
            children:
              '按金融企业安全要求打造的完整云上安全体系，全方位保障金融应用及数据安全。',
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '云监控' },
          content: {
            className: 'content3-content',
            children:
              '分布式云环境集中监控，统一资源及应用状态视图，智能分析及故障定位。',
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '移动' },
          content: {
            className: 'content3-content',
            children:
              '一站式移动金融APP开发及全面监控；丰富可用组件，动态发布和故障热修复。',
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '分布式中间件' },
          content: {
            className: 'content3-content',
            children:
              '金融级联机交易处理中间件，大规模分布式计算机，数万笔/秒级并发能力，严格保证交易数据统一性。',
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '大数据' },
          content: {
            className: 'content3-content',
            children:
              '一站式、全周期大数据协同工作平台，PB级数据处理、毫秒级数据分析工具。',
          },
        },
      },
    ],
  },
};
export const Teams00DataSource = {
  wrapper: { className: 'home-page-wrapper teams0-wrapper' },
  OverPack: { playScale: 0.3, className: 'home-page teams0' },
  BannerAnim: {
    className: 'banner-anim',
    children: [
      {
        name: 'elem0',
        className: 'teams0-banner-user-elem',
        titleWrapper: {
          className: 'teams0-content-wrapper',
          children: [
            {
              name: 'image',
              children:
                'https://powersuit.oss-cn-chengdu.aliyuncs.com/common/%E5%BD%A2%E8%B1%A1%E7%85%A7-%E6%AD%A3%E6%96%B9%E5%BD%A2.jpeg',
              className: 'teams0-image',
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>
                    探索用户体验与工程实践，由蚂蚁金服集团每年举办 1-2
                    次，包括专业分享、产品展台、Workshop 等内容。
                  </p>
                </span>
              ),
              className: 'teams0-content',
            },
            {
              name: 'title',
              children: (
                <span>
                  <p>南泽华</p>
                </span>
              ),
              className: 'teams0-h1',
            },
            {
              name: 'content2',
              children: (
                <span>
                  <p>POWERSUIT创始人、CEO</p>
                </span>
              ),
              className: 'teams0-content m3sjac7lyl-editor_css',
            },
          ],
        },
      },
    ],
  },
};
export const Pricing00DataSource = {
  wrapper: { className: 'home-page-wrapper pricing0-wrapper' },
  OverPack: { playScale: 0.3, className: 'home-page pricing0' },
  imgWrapper: { className: 'pricing0-img-wrapper', md: 12, xs: 24 },
  img: {
    className: 'pricing0-img',
    name: 'image',
    children:
      'https://powersuit.oss-cn-chengdu.aliyuncs.com/common/%E8%BD%AE%E6%92%AD%E5%9B%BE-%E5%95%86%E5%9F%8E-%E8%8C%B6%E5%8F%B6.jpeg',
  },
  childWrapper: {
    className: 'pricing0-text-wrapper',
    md: 12,
    xs: 24,
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <p>POWERSUIT · 茶城商城</p>
              </span>
            </span>
          </span>
        ),
        className: 'pricing0-title',
      },
      {
        name: 'content',
        children: (
          <span>
            <span>
              <p>中国六大茶类：红茶、绿茶、青茶、黄茶、黑茶、白茶。</p>
              <p>
                红茶：祁门红茶，正山小种， 锡兰红茶（产自斯里兰卡）等。
                功效:暖胃（冬日喝很舒服），提神醒脑，促消化，通便等。
              </p>
              <p>
                绿茶: 龙井茶，碧螺春，黄山毛峰，信阳毛尖，安吉白茶，六安瓜片等。
                功效: 清热去火，提神醒脑，降血脂，血糖，护齿明目等。
              </p>
              <p>
                青茶：铁观音，大红袍，肉桂，乌龙茶等。
                功效：辅助减肥，调节血脂等。
              </p>
              <p>黄茶：君山银针等。 功效：辅助减肥，清热去火等。</p>
              <p>黑茶：普洱茶等。 功效：除烦止渴，解腻清神等。</p>
              <p>
                白茶：白毫银针等。功效：促进血糖平衡(有利于糖尿病患者)、明目、保肝护肝。
              </p>
            </span>
          </span>
        ),
        className: 'pricing0-content',
      },
      {
        name: 'pricing',
        children: (
          <span>
            <p>品品香｜秦嗏｜斛生记｜中茶</p>
          </span>
        ),
        className: 'pricing0-pricing m3sjh2rp759-editor_css',
      },
      {
        name: 'button',
        children: {
          href: 'https://tea.powersuit.cn',
          type: 'primary',
          children: (
            <span>
              <p>
                立即购买<br />
              </p>
            </span>
          ),
          target: '_blank',
        },
      },
    ],
  },
};
export const Pricing01DataSource = {
  wrapper: { className: 'home-page-wrapper pricing0-wrapper' },
  OverPack: { playScale: 0.3, className: 'home-page pricing0' },
  imgWrapper: { className: 'pricing0-img-wrapper', md: 12, xs: 24 },
  img: {
    className: 'pricing0-img',
    name: 'image',
    children:
      'https://powersuit.oss-cn-chengdu.aliyuncs.com/common/%E8%BD%AE%E6%92%AD%E5%9B%BE-%E5%95%86%E5%9F%8E-%E7%A6%A7%E7%8E%9B%E8%AF%BA.png',
  },
  childWrapper: {
    className: 'pricing0-text-wrapper',
    md: 12,
    xs: 24,
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>POWERSUIT · 禧玛诺商城</p>
            </span>
          </span>
        ),
        className: 'pricing0-title',
      },
      {
        name: 'content',
        children:
          '云资源集中编排、弹性伸缩、持续发布和部署，高可用及容灾。按金融企业安全要求打造的完整云上安全体系，全方位保障金融应用及数据安全。<br />500-5Gbps，10 GB-50TB（含），1TB流量包，国内按峰值。',
        className: 'pricing0-content',
      },
      {
        name: 'pricing',
        children: (
          <span>
            <p>山地｜砂砾｜公路｜城市｜旅行</p>
          </span>
        ),
        className: 'pricing0-pricing m3sjhebepk-editor_css',
      },
      {
        name: 'button',
        children: {
          href: 'https://bike.powersuit.cn',
          type: 'primary',
          children: (
            <span>
              <span>
                <p>立即购买</p>
              </span>
            </span>
          ),
          target: '_blank',
        },
      },
    ],
  },
};
export const Content51DataSource = {
  wrapper: { className: 'home-page-wrapper content5-wrapper' },
  page: { className: 'home-page content5' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>产品简介</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <span>
              <p>POWERSUIT 主要的软件产品有以下几类</p>
            </span>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://powersuit.oss-cn-chengdu.aliyuncs.com/common/%E4%BA%A7%E5%93%81-%E5%95%86%E6%88%B7%E5%95%86%E5%9F%8E%E7%B3%BB%E7%BB%9F.png',
          },
          content: {
            children: (
              <span>
                <p>商户商城系统</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://powersuit.oss-cn-chengdu.aliyuncs.com/common/%E4%BA%A7%E5%93%81-%E7%A4%BE%E5%8C%BA%E5%9B%A2%E8%B4%AD%E7%B3%BB%E7%BB%9F.png',
          },
          content: {
            children: (
              <span>
                <p>社区团购系统</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://powersuit.oss-cn-chengdu.aliyuncs.com/common/%E4%BA%A7%E5%93%81-%E5%A4%96%E5%8D%96%E7%82%B9%E9%A4%90%E7%B3%BB%E7%BB%9F.png',
          },
          content: {
            children: (
              <span>
                <p>外卖点餐系统</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://powersuit.oss-cn-chengdu.aliyuncs.com/common/%E4%BA%A7%E5%93%81-%E4%B8%8A%E9%97%A8%E5%AE%B6%E6%94%BF%E7%B3%BB%E7%BB%9F.png',
          },
          content: {
            children: (
              <span>
                <p>上门家政系统</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://powersuit.oss-cn-chengdu.aliyuncs.com/common/%E4%BA%A7%E5%93%81-%E7%9F%A5%E8%AF%86%E4%BB%98%E8%B4%B9%E7%B3%BB%E7%BB%9F.png',
          },
          content: {
            children: (
              <span>
                <p>知识付费系统</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://powersuit.oss-cn-chengdu.aliyuncs.com/common/%E4%BA%A7%E5%93%81-%E5%9B%9E%E6%94%B6%E7%A7%9F%E8%B5%81%E7%B3%BB%E7%BB%9F.png',
          },
          content: {
            children: (
              <span>
                <p>回收租赁系统</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block6',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://powersuit.oss-cn-chengdu.aliyuncs.com/common/%E4%BA%A7%E5%93%81-%E5%90%8C%E5%9F%8E%E8%B7%91%E8%85%BF%E7%B3%BB%E7%BB%9F.png',
          },
          content: {
            children: (
              <span>
                <p>同城跑腿系统</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block7',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://powersuit.oss-cn-chengdu.aliyuncs.com/common/%E4%BA%A7%E5%93%81-CRM%E7%AE%A1%E7%90%86%E7%B3%BB%E7%BB%9F.png',
          },
          content: {
            children: (
              <span>
                <p>CRM系统</p>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: '产品与服务' }],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '一站式业务接入',
            },
            { name: 'content', children: '支付、结算、核算接入产品效率翻四倍' },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '一站式事中风险监控',
            },
            {
              name: 'content',
              children: '在所有需求配置环节事前风险控制和质量控制能力',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '一站式数据运营',
            },
            {
              name: 'content',
              children: '沉淀产品接入效率和运营小二工作效率数据',
            },
          ],
        },
      },
    ],
  },
};
export const Content50DataSource = {
  wrapper: { className: 'home-page-wrapper content5-wrapper' },
  page: { className: 'home-page content5' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>战略伙伴</p>
            </span>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <p>POWERSUIT 主要的合作伙伴</p>
                </span>
              </span>
            </span>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://pp.myapp.com/ma_icon/0/icon_42274589_1711095113/256',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>阿里云</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://zhengxin-pub.cdn.bcebos.com/logopic/76e638bf0e56679f5a1f452daf3f6f52_fullsize.jpg',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>华为云</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://pp.myapp.com/ma_icon/0/icon_52532321_1711348682/256',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>腾讯云</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://pp.myapp.com/ma_icon/0/icon_7643_1711073348/256',
          },
          content: {
            children: (
              <span>
                <p>快递100</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://pp.myapp.com/ma_icon/0/icon_5294_1710896817/256',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>支付宝</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://app-center.cdn.bcebos.com/appcenter/file/upload/a7c39683dd48fe24bc9df0c4ba12d260e5e9df79-72d0-4bed-9f72-fcfff23226d7.png',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>微信</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block6',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://pp.myapp.com/ma_icon/0/icon_54359640_1706059762/256',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>宝塔</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block7',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://pp.myapp.com/ma_icon/0/icon_11215225_1705455851/256',
          },
          content: {
            children: (
              <span>
                <p>哒哒</p>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children:
            'https://powersuit.oss-cn-chengdu.aliyuncs.com/common/%E4%BA%8C%E7%BB%B4%E7%A0%81-%E5%85%AC%E4%BC%97%E5%8F%B7-15cm.jpeg',
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <span>
                    <p>联系地址：陕西省西安市高新区</p>
                    <p>联系邮箱：powersuit@163.com</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '产品' },
        childWrapper: {
          children: [
            { name: 'link0', href: '#', children: '产品更新记录' },
            { name: 'link1', href: '#', children: 'API文档' },
            { name: 'link2', href: '#', children: '快速入门' },
            { name: 'link3', href: '#', children: '参考指南' },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '关于' },
        childWrapper: {
          children: [
            { href: '#', name: 'link0', children: 'FAQ' },
            { href: '#', name: 'link1', children: '联系我们' },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '资源' },
        childWrapper: {
          children: [
            {
              href: '#',
              name: 'link0',
              children: (
                <span>
                  <p>Design</p>
                </span>
              ),
            },
            {
              href: '#',
              name: 'link1',
              children: (
                <span>
                  <p>
                    <span>Motion</span>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <p>Copyright © 2023 陕西南星泽兰商贸有限公司 陕ICP备2023012976号</p>
      </span>
    ),
  },
};
